<template>
  <div>
    <v-container>
      <v-row justify="center" class="mt-10 mb-6">
        <v-container style="height: 400px;">
          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col
              class="text-subtitle-1 text-center"
              cols="12"
            >
              Coming soon
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "MyWill",
  directives: {
  },
  components: {
  },
  data: () => ({
  }),
  watch: {
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
</style>
